/* ==================================================================================== 

    MIXIN

==================================================================================== */

@mixin fs($size, $base: 10) {
    font-size: $size + px;
    font-size: calc($size / $base) + rem;
}

@mixin transition($time,$easing) {
    transition: all $time $easing;
}

@mixin translate($x,$y) {
    -webkit-transform: translate($x,$y);
            transform: translate($x,$y);
}

@mixin translateX($x) {
    -webkit-transform: translateX($x);
            transform: translateX($x);
}

@mixin translateY($y) {
    -webkit-transform: translateY($y);
            transform: translateY($y);
}

@mixin flex {
    display: -webkit-box; /*Android4.3*/
    display: -moz-box;    /*Firefox21*/
    display: -ms-flexbox; /*IE10*/
    display: -webkit-flex; /*PC-Safari,iOS8.4*/
    display: flex
}

@mixin between {
    -webkit-box-pack: justify; /*Android4.3*/
    -moz-box-pack: justify;    /*Firefox21*/
    -ms-flex-pack: justify;    /*IE10*/
    -webkit-justify-content: space-between; /*PC-Safari,iOS8.4*/
    justify-content: space-between
}

@mixin flexright {
    -webkit-justify-content: flex-end;
    justify-content: flex-end;
}
@mixin flexbot {
    -webkit-align-items: flex-end;
    align-items: flex-end;
}

@mixin vertical {
    -webkit-align-items: center; /* 縦方向中央揃え（Safari用） */
    align-items: center; /* 縦方向中央揃え */
}

@mixin center {
    -webkit-box-pack: center;
       -moz-box-pack: center;
        -ms-flex-pack:center;
        -ms-flex-box: center;
    -webkit-justify-content: center;
       -moz-justify-content: center;
        -ms-justify-content: center;
            justify-content: center;
}

@mixin wrap {
    -webkit-flex-wrap: wrap;
       -moz-flex-wrap: wrap;
        -ms-flex-wrap: wrap;
            flex-wrap: wrap;
}

@mixin nowrap {
    -webkit-flex-wrap: nowrap;
       -moz-flex-wrap: nowrap;
        -ms-flex-wrap: nowrap;
            flex-wrap: nowrap;
}

@mixin ap {
    -webkit-appearance: none;
       -moz-appearance: none;
        -ms-appearance: none;
            appearance: none;
}

@mixin reset {
    -webkit-appearance: none;
       -moz-appearance: none;
        -ms-appearance: none;
            appearance: none;
    border-radius: 0;
    border: 0;
    margin: 0;
    padding: 0;
    background: none transparent;
    vertical-align: middle;
    font-size: inherit;
    color: inherit;
}

@mixin ts($xy, $s, $c) {
    text-shadow: $xy 0 $s $c,
                 -$xy 0 $s $c,
                 0 $xy $s $c,
                 0 (-$xy) $s $c;
}

@mixin hover {
    @include transition(.5s,ease);
    &:hover {
        opacity: 0.6;
    }
}

@mixin small {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

@mixin g {
    -webkit-transform: translate3d(0,0,0);
            transform: translate3d(0,0,0);
}

@mixin max($w) {
  @media only screen and (max-width: #{$w}){
    @content;
  }
}
@mixin min($w) {
  @media only screen and (min-width: #{$w}){
    @content;
  }
}

@mixin retina {
    @media only screen and (-webkit-min-device-pixel-ratio: 2) {
        @content;
    }
}


