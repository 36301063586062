@include max(1140px) {

	.title-type1 {
		padding-left: 140px;
		&:before {
			@include translate(0,-50%);
		}
	}

}


@include max(1020px) {

	html {
		font-size: 1vw;
	}

	.mv {
		margin: 40px 0;
		padding-left: 20px;
		padding-right: 20px;
	}


}


@include max(960px) {

	.header-logo {
		width: 250px;
	}


	#common-nav {
		ul {
			li {
				margin-left: 16px;
				margin-right: 16px;
			}
		}
	}

}

@include max(768px) {

	.header-logo {
		width: 220px;
	}

	.header-link {
		a {
			padding-left: 20px;
			padding-right: 20px;
		}
	}

	#common-nav {
		ul {
			li {
				padding-top: 18px;
				padding-bottom: 18px;
				margin-left: 10px;
				margin-right: 10px;
			}
		}
	}

	.form-list {
		li {
			width: 50%;
		}
	}

}


@include max(640px) {

	html {
		font-size: 1.8vw;
	}

	.content {
		padding-top: 50px;
		padding-bottom: 50px;
	}

	.content-m {
		margin-bottom: 50px;
		margin-top: 50px;
	}

	.flex {
		display: block;
	}

	.flex-box {
		width: 100% !important;
	}

	.flex-mb {
		margin-bottom: 30px;
	}

	.inner {
		padding-left: 20px;
		padding-right: 20px;
	}


	.pc {
		display: none !important;
	}
	.sp {
		display: block !important;
	}

	#common-header {
		padding: 20px 30px;
		background: $blue;
	}

	#nav-torigger {
		display: block;
	}

	#common-nav {
		padding-top: 30px;
		display: none;
		-webkit-transition: none;
		        transition: none;
		opacity: 0;
		position: fixed;
		top: 84px;
		left: 0;
		width: 100vw;
		height: 100vh;
		background: $blue;
		overflow-y: scroll;
		ul {
			padding: 0;
			&:after {
				content: none;
			}
			li {
				margin: 0;
				padding: 0;
				display: block;
				a {
					padding: 25px 40px;
					display: block;
					color: #fff;
					@include fs(20);
					font-weight: 200;
					border-bottom: 1px solid #fff;
				}
				&.hoverNav {
					a {
						&:before {
						    content: '';
						    width: 10px;
						    height: 6px;
						    background: url(../img/icon-arrow-bot.svg) no-repeat center;
						    background-size: 100% 100%;
						    position: absolute;
						    top: 50%;
						    right: 20px;
						    -webkit-transform: translateY(-50%);
						    transform: translateY(-50%);
						    @include transition(.5s,ease);
						}
						&.open {
							&:before {
							    transform: translateY(-50%) rotate(180deg);
							}
						}
					}
					.child {
						display: none;
						padding: 0;
						position: static;
						width: 100%;
						-webkit-transform: none;
						        transform: none;
						li {
							a {
								padding: 20px 50px;
								font-size: 1.8rem;
								background: #A7AEB1;
								&:before {
									content: none;
								}
							}
						}
					}
				}
			}
		}
	}

	#common-header {
		h1 {
			img {
				width: 140px;
			}
		}
	}

	.footer-main {
		padding-left: 20px;
		padding-right: 20px;
	}

	.footer-box {
		margin-bottom: 40px;
		dl {
			dt {
				padding-bottom: 10px;
				@include fs(22);
				&.footer-link {
					a {
						padding-top: 15px;
						padding-bottom: 15px;
						width: 100%;
					}
				}
			}
			dd {
				@include fs(15);
				ul {
					li {
						margin-right: 40px;
						padding-left: 15px;
						display: inline-block;
						vertical-align: middle;
						&:before {
							width: 4px;
							height: 4px;
							top: 50%;
							@include translateY(-50%);
						}
						a {
							font-size: 1.6rem;
						}
					}
				}
			}
		}
	}

	.mv {
		margin: 0;
		padding: 0;
	}

	.contact-link {
		width: 60px;
		bottom: 15px;
		right: 15px;
	}

	.k-box-wrapper {
		padding-top: 0;
	}

	.k-box-right {
		margin-bottom: 20px;
	}

	.k-box {
		width: 100%;
		&.k-box3 {
			position: relative;
			display: block;
			h3,
			.k-box-info {
				width: 100%;
				display: block;
			}
			.k-box-info {
				padding: 20px;
			}
			&:after {
				top: 10px;
				left: 10px;
			}
		}
	}

	.title-type1 {
		margin-bottom: 40px;
	}

	.title-type2 {
		margin-bottom: 20px;
		font-size: 2.3rem;
	}

	.title-type3 {
		margin-bottom: 15px;
		font-size: 2.2rem;
		letter-spacing: 0;
	}

	.clm-content {
		margin-top: 30px;
		margin-bottom: 60px;
	}

	.btn-box {
		margin: 60px 0 20px;
	}

	.list4 {
		li {
			&:nth-child(4n) {
				margin-right: 2%;
			}
		}
	}

	.list3 {
		li {
			&:nth-child(3n) {
				margin-right: 2%;
			}
		}
	}

	.list {
		justify-content: normal;
		li {
			margin-right: 2%;
			width: 49%;
			&:nth-child(2n) {
				margin-right: 0;
			}
			dl {
				dt {
					padding-left: 5px;
					padding-right: 5px;
					line-height: 1.2;
				}
			}
		}
	}

	.bannar-jirei-box {
		text-align: center;
		& > img {
			width: 100%;
		}
		a {
			width: 100%;
			position: static;
		}
	}

	.title-type3 {
		span {
			transition-delay: 0s;
			&:after {
				transition-delay: .8s;
			}
		}
	}

	.slick-slide {
		padding-left: 40px;
		padding-right: 40px;
	}

	.slide-controls {
		font-size: 4rem;
	}

	.pro-box {
		padding: 80px 0;
	}

	.pro-num {
		font-size: 20rem;
		left: 0;
		top: -50px;
		img {
			height: 150px;
		}
	}

	.page-nav-num {
		margin-top: 10px;
		margin-left: 0;
		padding-left: 0;
		border-left: none;
	}

	.pt120 {
		padding-top: 100px;
	}

	.sche {
		dd {
			padding: 30px 0;
		}
	}

	.form-table {
		tr {
			margin-bottom: 20px;
			display: block;
			width: 100%;
			th,
			td {
				padding: 0;
				width: 100%;
				display: block;
				font-size: 16px;
			}
			th {
				margin-bottom: 10px;
			}
			td {
				padding-bottom: 20px;
			}
		}
	}

	.form-label {
		font-size: 16px;
	}

	.form-input {
		height: 55px;
	}

	.select-box {
		min-width: 50%;
	}

	.form-list {
		&.sp-list {
			display: block;
			li {
				margin: 20px 0;
				padding: 10px 0;
				width: 100%;
			}
		}
	}

	.complete-wrapper {
		.form-table {
			tr {
				margin-bottom: 0;
				padding: 20px;
				th {
					padding-left: 0;
					font-weight: normal;
				}
				td {
					padding-bottom: 0;
				}
			}
		}
		.form-label {
			padding-bottom: 10px;
			padding-right: 20px;
		}
		.form-label + .form-table {
			tr {
				padding-top: 0;
			}
		}
	}

	.chara-info {
		h3 {
			@include fs(24);
		}
	}

	.form-item {
		width: 100%;
	}

	.select-box {
		width: 50%;
	}

	.bg-sp-none {
		margin-bottom: 0;
		padding-bottom: 45px !important;
		background: none;
	}

	.sppn {
		padding-bottom: 45px !important;
	}

	.bg-sp-grey {
		padding-bottom: 80px !important;
		padding-top: 80px !important;
		background: #F2F2F2;
	}

	.sp-pb-none {
		padding-bottom: 0 !important;
	}

	.mv-items {
		padding-left: 20px;
		padding-right: 20px;
	}

	.mv-logo {
		width: calc(360px / 750px * 100%);
	}

	.mv-text1 {
		margin: 5% 0;
		width: calc(623px / 750px * 100%);
	}
	.mv-text2 {
		width: calc(463px / 750px * 100%);
	}
	.mv-text4 {
		margin-top: -20%;
		width: 112%;
	}

	.oys {
		overflow-x: scroll;
	  img {
	    width: 640px;
	    max-width: none;
	  }
	}

	.question-box {
		dt {
			@include between;
			span {
				width: 70%;
			}
			button {
				padding-top: 6px;
				padding-bottom: 6px;
				min-width: 120px;
			}
		}
	}

	.interview-content {
		margin-bottom: 60px;
	}

	.caseline {
		margin-bottom: 50px;
	}

	.interview-wrapper {
		.title-type3 {
			margin-top: 10px;
			display: block;
		}
		dd {
			padding-left: 0;
			p {
				font-size: 1.8rem;
			}
			.interview-box {
				p {
					font-size: 1.8rem;
				}
			}
		}
	}

	.interview-box {
		margin: 45px 0;
		img {
			.fl,
			.fr {
				margin: 0 0 20px;
				font: none;
			}
			width: 100%;
		}
	}

	.interview-icon {
		padding: 6px 20px;
		width: auto;
	}

	.footer-box {
		dl {
			dd {
				ul {
					&.footer-nav {
						display: block;
						li {
							width: 100%;
						}
					}
				}
			}
		}
	}

	.case-img {
		margin-bottom: 20px;
	}

	.float-box {
		img {
			.fl,
			.fr {
				margin: 0;
				margin-bottom: 20px;
			}
		}
	}

}

@include max(480px) {

	html {
		font-size: 2vw;
	}

	.inner {
		padding-left: 15px;
		padding-right: 15px;
	}

	#common-header {
		h1 {
			img {
				width: 120px;
			}
		}
	}

	.content {
		padding-top: 45px;
		padding-bottom: 45px;
	}

	.pt120 {
		padding-top: 80px;
	}

	.footer-box {
		margin-bottom: 30px;
	}

	.question-box {
		margin: 45px 0;
		dt {
			padding: 0;
			padding-left: 36px;
			&:before {
				width: 22px;
				height: 22px;
			}
			button {
				min-width: 95px;
				&.active {
					min-width: 105px;
				}
			}
		}
		dd {
			margin-top: 15px;
			padding-left: 36px;
			padding-top: 0;
			font-size: 1.5rem;
			line-height: 1.6;
			&:before {
				width: 22px;
				height: 22px;
			}
		}
	}

}



@include max(360px) {

	html {
		font-size: 2.2vw;
	}

	#common-header,
	.inner,
	.sp-device-img {
		padding-left: 20px;
		padding-right: 20px;
	}

}













