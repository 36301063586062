/* ====================================================================================

    COMMON

==================================================================================== */

.transition {
	@include transition(.5s,ease);
}

/* ====================================================================================

    HEADER

==================================================================================== */

#common-header {
	@include flex;
	@include vertical;
	background: #fff;
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	z-index: 50;
}

/* ====================================================================================

    NAv

==================================================================================== */

#common-nav {
	@include transition(.5s,ease);
	ul {
		padding: 0 20px;
		font-size: 0;
		position: relative;
		li {
			margin: 0 10px;
			padding: 20px;
			display: inline-block;
			vertical-align: middle;
			@include fs(14);
			&.hoverNav {
				position: relative;
				ul {
					display: none;
					padding: 24px;
					position: absolute;
					bottom: 0;
					left: 0;
					width: 265px;
					@include translateY(100%);
					z-index: 1;
					background: #2AA8C8;
					&:before {
						content: '';
						width: 22px;
						height: 20px;
						background: url(../img/icon-child.svg) no-repeat center;
						background-size: 100% 100%;
						position: absolute;
						top: -10px;
						left: 24px;
					}
					li {
						padding: 0;
						margin: 0;
						display: block;
						a {
							padding: 10px 0;
							display: block;
							color: #fff;
							font-size: 1.4rem;
							font-weight: 300;
							position: relative;
							@include hover;
							&:before {
								content: '';
								width: 10px;
								height: 6px;
								background: url(../img/icon-arrow-bot.svg) no-repeat center;
								background-size: 100% 100%;
								position: absolute;
								top: 50%;
								right: 0;
								@include translateY(-50%);
							}
						}
					}
				}
			}
			a {
				padding-top: 8px;
				padding-bottom: 8px;
				color: #7E7E7E;
				display: inline-block;
				line-height: 1.2;
				position: relative;
				letter-spacing: 2px;
				&:after {
					content: '';
					width: 100%;
					height: 1px;
					background: $blue;
					position: absolute;
					bottom: 0;
					left: 0;
					-webkit-transition: -webkit-transform 0.4s cubic-bezier(0.4, 0, 0.2, 1);
				    transition: transform 0.4s cubic-bezier(0.4, 0, 0.2, 1), -webkit-transform 0.4s cubic-bezier(0.4, 0, 0.2, 1);
				    -webkit-transform: scaleX(0);
				    -ms-transform: scaleX(0);
				    transform: scaleX(0);
				    -webkit-transform-origin: right center;
				    -ms-transform-origin: right center;
				    transform-origin: right center;
				}
				&.active {
					&:after {
					    -webkit-transform: scaleX(1);
					    transform: scaleX(1);
					    height: 2px;
					}
				}
				@media only screen and (min-width:768px) {
					@include transition(.5s,ease);
					&:hover {
						&:after {
							-webkit-transform: scaleX(1);
						    -ms-transform: scaleX(1);
						    -ms-transform-origin: left center;
						    transform-origin: left center;
						}
					}
				}
			}
		}
	}
}

.contact-link {
	position: fixed;
	bottom: 20px;
	right: 20px;
	z-index: 100;
}

#nav-torigger {
	display: none;
	width: 32px;
	height: 22px;
	position: absolute;
	top: 50%;
	right: 30px;
	@include translateY(-50%);
	span {
		display: block;
		background: #fff;
		width: 100%;
		height: 2px;
		position: absolute;
		left: 0;
		@include transition(.5s,ease);
		&:nth-of-type(1) {
			top: 0;
		}
		&:nth-of-type(2) {
			top: 50%;
			@include translateY(-50%);
		}
		&:nth-of-type(3) {
			bottom: 0;
		}
	}
	&.active {
		span {
			&:nth-of-type(1) {
				-webkit-transform: translate(0,10px) rotate(45deg);
				        transform: translate(0,10px) rotate(45deg);
			}
			&:nth-of-type(2) {
				opacity: 0;
			}
			&:nth-of-type(3) {
				-webkit-transform: translate(0,-10px) rotate(-45deg);
				        transform: translate(0,-10px) rotate(-45deg);
			}
		}
	}
}


/* ====================================================================================

    SIDE

==================================================================================== */

/* ====================================================================================

    FOOTER

==================================================================================== */

.footer-main {
	padding-top: 40px;
	padding-bottom: 40px;
	background: #1E7C94;
	color: #fff;
}

.time-info {
	margin-bottom: 30px;
	color: #bcbcbc;
}

.footer-box {
	width: calc(235px / 980px * 100%);
	dl {
		dt {
			margin-bottom: 15px;
			padding-bottom: 5px;
			border-bottom: 1px solid #fff;
			@include fs(14);
			&.footer-link {
				padding-bottom: 0;
				border-bottom: none;
				a {
					padding: 10px 20px;
					text-align: center;
					font-weight: normal;
					border-radius: 5px;
					color: #fff;
					border: 1px solid #fff;
					width: 280px;
					max-width: 100%;
					display: inline-block;
					@media only screen and (min-width:768px) {
						@include transition(.5s,ease);
						&:hover {
							background: #fff;
							color: $blue;
						}
					}
				}
			}
		}
		dd {
			line-height: 1.6;
			@include fs(12);
			ul {
				li {
					margin: 5px 0;
					padding-left: 20px;
					position: relative;
					&:before {
						content: '';
						width: 5px;
						height: 5px;
						background: url(../img/icon-square.svg) no-repeat center;
						background-size: 100% 100%;
						position: absolute;
						top: 9px;
						left: 0;
					}
					a {
						color: #fff;
						@media only screen and (min-width:768px) {
							@include transition(.5s,ease);
							&:hover {
								opacity: 0.6;
							}
						}
					}
				}
				&.footer-nav {
					@include flex;
					@include wrap;
					li {
						width: 50%;
					}
				}
			}
		}
	}
}


.copy {
	padding-top: 10px;
	padding-bottom: 10px;
	background: #010331;
	p {
		color: #fff;
		text-align: center;
		line-height: 1.2;
		small {
			font-size: 10px;
			letter-spacing: 1px;
		}
	}
}






















