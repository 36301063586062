#main {
  padding-bottom: 100px;
}

.k-box-wrapper {
  position: relative;
}

.bg {
  position: absolute;
  bottom: 0;
  left: 50%;
  -webkit-transform: translateX(-50%) !important;
  transform: translateX(-50%) !important;
  z-index: -1;
}

.bg1 {
  width: 1120px;
  height: 817px;
  background: url(../img/bg1@2x.jpg) no-repeat center;
  background-size: 100% 100%;
}
.bg2 {
  width: 1120px;
  height: 670px;
  background: url(../img/bg2@2x.jpg) no-repeat center;
  background-size: 100% 100%;
}
.bg3 {
  width: 1120px;
  height: 957px;
  background: url(../img/bg3@2x.jpg) no-repeat center;
  background-size: 100% 100%;
}

.k-box {
  h3 {
    padding: 18px 15px;
    color: #fff;
    text-align: center;
    font-weight: bold;
  }
  &.k-box1 {
    h3 {
      background: #2388de;
    }
    .k-box-info-left {
      width: calc(160px / 383px * 100%);
      dl {
        margin-bottom: 15px;
        dt {
          border-color: #2388de;
          color: #2388de;
          &:after {
            bottom: -10px;
            height: 15px;
            background: #2388de;
          }
        }
      }
    }
    .k-box-info-right {
      width: calc(220px / 383px * 100%);
    }
  }
  &.k-box2 {
    h3 {
      background: #009944;
    }
    .k-box-info-left {
      width: calc(230px / 383px * 100%);
      dl {
        margin-bottom: 40px;
        dt {
          border-color: #009944;
          color: #009944;
          &:after {
            bottom: -10px;
            height: 56px;
            background: #009944;
          }
        }
      }
    }
    .k-box-info-right {
      width: calc(140px / 383px * 100%);
    }
  }
  &.k-box3 {
    margin-top: 20px;
    @include flex;
    .k-box-info {
      padding: 20px 40px;
      z-index: 1;
      width: calc(550px / 880px * 100%);
    }
    h3 {
      padding: 30px 20px;
      text-align: center;
      background: $blue;
      width: calc(330px / 880px * 100%);
    }
  }
}

.k-box-shadow {
  z-index: 1;
  position: relative;
}

.k-box-info {
  padding: 20px;
  background: #fff;
  p {
    small {
      @include fs(14);
    }
  }
}

.k-box-right {
  width: calc(430px / 880px * 100%);
  background: #fff;
  .k-box-info {
    padding-top: 30px;
    width: 100%;
  }
}

p + .k-box-flex {
  margin-top: 20px;
}

.k-box-flex {
  width: 100%;
  @include flex;
  @include between;
}

.k-box-info-left {
  dl {
    padding: 10px 0;
    @include flex;
    @include vertical;
    &:first-child {
      padding-top: 0;
    }
    &:last-child {
      margin-bottom: 0 !important;
      padding-bottom: 0;
      dt {
        margin-bottom: 0 !important;
        &:after {
          content: none;
        }
      }
    }
    dt {
      padding: 3px 10px;
      @include fs(10);
      position: relative;
      border: 1px solid;
      border-radius: 10px;
      &:after {
        content: "";
        width: 1px;
        position: absolute;
        left: 50%;
        @include translate(-50%, 100%);
      }
    }
    dd {
      margin-left: 10px;
      @include fs(12);
      color: #313131;
      line-height: 1.4;
    }
  }
}

.k-box-info-right {
  text-align: center;
  figure {
    figcaption {
      margin-top: 10px;
      line-height: 1.4;
      @include fs(14);
    }
  }
  figure + figure {
    margin-top: 16px;
  }
}

.mv {
  margin: 60px 0;
  padding-left: 60px;
  padding-right: 60px;
  text-align: center;
}

.mv-inner {
  position: relative;
}

.mv-items {
  padding-left: calc(170px / 1240px * 100%);
  width: 100%;
  position: absolute;
  top: 54%;
  left: 0;
  @include translateY(-50%);
}

.mv-logo {
  width: calc(332px / 950px * 100%);
}

.mv-text1 {
  margin: 30px 0;
  width: calc(304px / 950px * 100%);
}

.mv-text2 {
  width: calc(314px / 950px * 100%);
}

.mv-text3 {
  position: absolute;
  top: 0;
  right: 15px;
  width:calc( 30px / 950px * 100%);
}

.mv-text5 {
  width: calc(430px / 950px * 100%);
}

.catch {
  margin-bottom: 40px;
  text-align: center;
}

.catch-text {
  margin: 0 auto;
  width: 610px;
  max-width: 100%;
  font-size: 1.8rem;
}

.title-type1 {
  margin-bottom: 60px;
  padding-left: 80px;
  padding-top: 2px;
  font-family: $futura;
  line-height: 1;
  font-size: 1.8rem;
  position: relative;
  letter-spacing: 3px;
  color: #525252;
  font-weight: 500;
  &:before {
    content: "";
    width: 120px;
    height: 14px;
    position: absolute;
    top: 50%;
    left: 0;
    @include translate(-50%, -50%);
  }
}

.title-type2 {
  margin-bottom: 50px;
  font-size: 2.8rem;
  font-weight: 500;
  letter-spacing: 3px;
  line-height: 1.6;
}

.title-type3 {
  margin-bottom: 30px;
  font-size: 2.2rem;
  font-weight: 500;
  letter-spacing: 2px;
  line-height: 1.6;
  span {
    margin-bottom: 3px;
    padding-bottom: 3px;
    display: inline-block;
    position: relative;
    @include translateY(100%);
    opacity: 0;
    will-change: transform, opacity;
    -webkit-transition: all 0.8s ease 0.25s;
    transition: all 0.8s ease 0.25s;
    &:after {
      content: "";
      width: 0%;
      height: 1px;
      position: absolute;
      bottom: 0;
      left: 0;
      will-change: width;
      -webkit-transition: all 0.8s ease 1.05s;
      transition: all 0.8s ease 1.05s;
    }
  }
  &.active {
    span {
      @include translateY(0%);
      opacity: 1;
      &:after {
        width: 100%;
      }
    }
  }
}

.title-type4 {
  margin-bottom: 15px;
  font-size: 2rem;
  font-weight: 500;
}

$color1: #2a85c8;
$color2: #4eacd6;
$color3: #19bec4;
$color4: #2cbe96;
$color5: #009944;

.color-type1 {
  .title-type1 {
    &:before {
      background: linear-gradient(
        to right,
        rgba(42, 133, 200, 1) 0%,
        rgba(49, 218, 240, 1) 100%
      );
    }
  }
  .title-type2,
  .title-type3 {
    color: $color1;
  }
  .title-type3 span:after {
    background: #2cbbd7;
  }
  .btn {
    background: $color1;
    border-color: $color1;
    @include min(769px) {
      &:hover {
        color: $color1;
      }
    }
  }
}
.color-type2 {
  .title-type1 {
    &:before {
      background: linear-gradient(
        to right,
        rgba(61, 244, 223, 1) 0%,
        rgba(48, 195, 232, 1) 100%
      );
    }
  }
  .title-type2,
  .title-type3 {
    color: $color2;
  }
  .title-type3 span:after {
    background: #3bcbd8;
  }
  .btn {
    background: $color2;
    border-color: $color2;
    @include min(769px) {
      &:hover {
        color: $color2;
      }
    }
  }
}
.color-type3 {
  .title-type1 {
    &:before {
      background: linear-gradient(to right, #19bec4 0%, #2aa8c8 100%);
    }
  }
  .title-type2,
  .title-type3 {
    color: $color3;
  }
  .title-type3 span:after {
    background: #19bec4;
  }
  .btn {
    background: $color3;
    border-color: $color3;
    @include min(769px) {
      &:hover {
        color: $color3;
      }
    }
  }
  .list {
    li {
      dl {
        dt {
          background: $color3;
        }
      }
    }
  }
}
.color-type4 {
  .title-type1 {
    &:before {
      background: linear-gradient(to right, #69d1cd 0%, #2cbe96 100%);
    }
  }
  .title-type2 {
    color: $color4;
  }
  .title-type3 {
    color: #249d7c;
  }
  .title-type3 span:after {
    background: #2cbe96;
  }
  .title-type4 {
    color: #249d7c;
  }
  .btn {
    background: $color4;
    border-color: $color4;
    @include min(769px) {
      &:hover {
        color: $color4;
      }
    }
  }
  .list {
    li {
      dl {
        dt {
          background: $color4;
        }
      }
    }
  }
}
.color-type5 {
  .title-type1 {
    &:before {
      background: linear-gradient(to right, #009944 0%, #006c2f 100%);
    }
  }
  .title-type2 {
    color: $color5;
  }
}

.first-box {
  order: -1;
}

.clm-content {
  margin: 40px 0;
}

.w360 {
  width: calc(360px / 980px * 100%);
}
.w380 {
  width: calc(380px / 980px * 100%);
}
.w420 {
  width: calc(420px / 980px * 100%);
}
.w490 {
  width: calc(490px / 980px * 100%);
}
.w450 {
  width: calc(450px / 980px * 100%);
}
.w440 {
  width: calc(440px / 980px * 100%);
}
.w500 {
  width: calc(500px / 980px * 100%);
}
.w520 {
  width: calc(520px / 980px * 100%);
}
.w570 {
  width: calc(570px / 980px * 100%);
}
.w590 {
  width: calc(590px / 980px * 100%);
}

.btn-box {
  margin: 80px 0 40px;
  text-align: center;
}

.btn {
  padding: 15px;
  width: 490px;
  max-width: 100%;
  display: inline-block;
  font-weight: 200;
  color: #fff;
  font-size: 2.4rem;
  letter-spacing: 3px;
  border: 1px solid;
  box-shadow: 0 3px 5px rgba(0, 0, 0, 0.2);
  @include min(769px) {
    @include transition(0.5s, ease);
    &:hover {
      background: #fff;
    }
  }
}

.link {
  color: #2a85c8;
  text-decoration: underline;
}

.list {
  margin: 40px 0 20px;
  @include flex;
  @include wrap;
  @include center;
  li {
    margin-bottom: 30px;
    background: #fff;
    dl {
      dt {
        padding: 18px;
        color: #fff;
        font-size: 1.7rem;
        text-align: center;
      }
      dd {
        padding: 25px 15px;
        text-align: center;
        p {
          margin-top: 25px;
          font-size: 1.4rem;
          line-height: 1.6;
        }
      }
    }
  }
}

.list4 {
  li {
    margin-right:calc( 17px / 980px * 100%);
    width: calc(232px / 980px * 100%);
    &:nth-child(4n) {
      margin-right: 0;
    }
  }
}

.list3 {
  li {
    margin-right:calc( 29px / 980px * 100%);
    width: calc(307px / 980px * 100%);
    &:nth-child(3n) {
      margin-right: 0;
    }
  }
}

.content-m {
  margin-top: 60px;
  margin-bottom: 60px;
}

.bg-grey {
  background: #f2f2f2;
}

.bannar-jirei-box {
  text-align: right;
  position: relative;
  & > img {
    width: calc(435px / 980px * 100%);
  }
  a {
    display: block;
    position: absolute;
    bottom: -30px;
    left: 0;
    width: calc(657px / 980px * 100%);
    @include min(769px) {
      @include transition(0.3s, ease);
      &:hover {
        -webkit-transform: scale(1.03);
        transform: scale(1.03);
      }
    }
    &:after {
      content: "";
      width: 100%;
      height: 100%;
      display: block;
      background: rgba(255, 255, 255, 0.3);
      position: absolute;
      top: 0;
      left: 0;
      opacity: 0;
      @include transition(0.5s, ease);
    }
    @include min(769px) {
      &:hover {
        &:after {
          opacity: 1;
        }
      }
    }
  }
}

.caption {
  margin-bottom: 60px;
}

.anm {
  will-change: transform, opacity;
}

.mv-page {
  border-top: 2px solid #fff;
  margin-bottom: 60px;
}

.page-nav-box {
  position: relative;
  .title-type1 {
    margin-bottom: 0;
    line-height: 1;
  }
}

.page-nav {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 5;
  padding: 12px 20px;
  background: rgba(255, 255, 255, 0.8);
}

.fixed {
  position: fixed;
}

.page-nav-num {
  margin-left: 15px;
  padding-left: 20px;
  border-left: 1px solid #525252;
  @include flex;
  font-size: 0;
  li {
    margin: 0 8px;
    &.active {
      a {
        color: rgba(42, 133, 200, 0.5);
      }
    }
    a {
      font-size: 2.8rem;
      font-family: $futura;
      color: #c4c4c4;
    }
  }
}

.page-nav-inner {
  display: inline-block;
}

.pro-box {
  padding: 80px 0;
  &:nth-of-type(2n) {
    background: url(../img/bg-pro@2x.png) no-repeat center;
    background-size: cover;
  }
  .title-type2 {
    margin-bottom: 24px;
    padding-left: 100px;
  }
}

.pro-box-inner {
  z-index: 1;
  position: relative;
}

.pro-num {
  font-size: 30rem;
  color: #b9b9b9;
  font-family: $futura;
  opacity: 0.45;
  position: absolute;
  top: -100px;
  left: -60px;
  z-index: 0;
}

.pro-slider-wrap {
  margin-top: 100px;
  position: relative;
  z-index: 1;
}

.pro-slider {
  margin: 0 auto;
  width: 680px;
  max-width: 100%;
  li {
    text-align: center;
    span {
      margin-top: 15px;
      color: #707070;
      font-size: 1.2rem;
      display: inline-block;
      text-align: center;
    }
    em {
      margin-left: 5px;
      padding: 2px 8px;
      border-radius: 10px;
      display: inline-block;
      background: #2aa8c8;
      color: #fff;
      font-size: 10px;
    }
  }
}

.slick-slide {
  padding: 0 60px;
}

.pro-slider {
  li {
    img {
      border: 1px solid #ccc;
    }
  }
}

#sb-title {
  bottom: -10px;
  left: 50%;
  right: auto;
  @include translateX(-50%);
  color: #fff;
  font-size: 14px;
  opacity: 1;
}

.slick-list {
  overflow: visible;
}

.slide-controls {
  position: absolute;
  top: 50%;
  @include translateY(-50%);
  cursor: pointer;
  color: #2aa8c8;
  font-size: 3rem;
  z-index: 1;
  opacity: 1;
  @include transition(0.5s, ease);
}

.slide-next {
  right: 0;
}

.slide-prev {
  left: 0;
}

.slick-disabled {
  opacity: 0;
  cursor: default;
}

.pro-box7 {
  margin-top: -1px;
  padding: 18px;
  border: 2px solid #2aa8c8;
  dl {
    margin-top: 30px;
    dt {
      margin-bottom: 20px;
      text-align: center;
      font-size: 2rem;
      color: #2aa8c8;
    }
  }
}

.pro-flex7 {
  @include flex;
  @include between;
}

.pro7-left {
  width: calc(500px / 940px * 100%);
}

.pro7-right {
  width: calc(420px / 940px * 100%);
}

.pb0 {
  padding-bottom: 0;
}
.pb80 {
  padding-bottom: 80px;
}
.pt120 {
  padding-top: 120px;
}

.images {
  figcaption {
    padding-top: 5px;
    margin-bottom: 15px;
    line-height: 1.4;
    font-size: 1.6rem;
    font-weight: 300;
  }
}

.casebox {
  margin-top: 15px;
  padding: 25px 20px;
  background: #F2F2F2;
}

.sche {
  dt {
    padding: 8px 10px;
    background: #c3c3c3;
    color: #fff;
    font-size: 1.4rem;
  }
  dd {
    padding: 30px 40px;
    text-align: center;
    ul {
      margin-top: 30px;
      text-align: justify;
    }
  }
}

.caution {
  li {
    padding-left: 15px;
    font-size: 1.2rem;
    position: relative;
    line-height: 1.2;
    &:before {
      content: "※";
      position: absolute;
      top: 0;
      left: 0;
    }
  }
}

.oys {
  img {
    width: 640px;
  }
}

.bg-form {
  background: url(../img/bg-dot-form.png) repeat;
}

.title-h2 {
  margin-bottom: 30px;
  text-align: center;
  color: #2aa8c8;
  &.white {
    color: #fff;
  }
}

.title-h2-child,
.title-h2-main {
  display: block;
}

.title-h2-child {
  margin-bottom: 15px;
  @include fs(10);
  display: block;
  letter-spacing: 15px;
  font-family: $title;
}

.title-h2-main {
  @include fs(32);
  display: inline-block;
  letter-spacing: 8px;
  font-weight: bold;
  position: relative;
}

.complete-wrapper {
  margin-top: 100px;
  .form-table {
    margin-bottom: 0;
    tr {
      &:nth-child(odd) {
        background: #f6f7fb;
      }
      th {
        padding-left: 20px;
      }
      td {
        font-weight: bold;
      }
    }
  }
  .form-label {
    padding-left: 20px;
    padding-top: 20px;
    background: #f6f7fb;
  }
}

.form-table {
  margin-bottom: 60px;
  width: 100%;
  tr {
    th,
    td {
      padding-top: 10px;
      padding-bottom: 15px;
      color: #808080;
      @include fs(14);
    }
    th {
      padding-top: 15px;
      width: calc(220px / 980px * 100%);
      line-height: 1.4;
      vertical-align: top;
    }
    td {
      position: relative;
    }
  }
}

.form-list + .error {
  bottom: 5px;
}

.error {
  color: #F90404;
  font-size: 10px;
  position: absolute;
  bottom: -3px;
  left: 0;
  img {
    margin-right: 8px;
    vertical-align: middle;
  }
}

.form-label {
  color: #808080;
  line-height: 1.4;
  @include fs(14);
}

.req {
  color: $blue;
}

.form-item {
  padding-left: 15px;
  padding-right: 15px;
  border: 1px solid #e4e4e4;
  background: #fff;
  border-radius: 5px;
  width: 100%;
}

.form-input {
  height: 35px;
}

.form-text {
  padding-top: 10px;
  padding-bottom: 10px;
  min-height: 200px;
  resize: vertical;
}

.form-list {
  @include flex;
  @include wrap;
  li {
    margin-top: 15px;
    margin-bottom: 15px;
    width: 33%;
    position: relative;
  }
}

.size1 {
  width: calc(500px / 760px * 100%);
}
.size2 {
  width: calc(280px / 760px * 100%);
}
.size3 {
  width: calc(610px / 760px * 100%);
}
.size4 {
  width: calc(330px / 760px * 100%);
}

.checkbox {
  opacity: 0;
  position: absolute;
}

.checkbox + label {
  padding-left: 44px;
  position: relative;
  &:before,
  &:after {
    content: "";
    position: absolute;
    top: 50%;
    @include translateY(-50%);
  }
  &:before {
    width: 28px;
    height: 28px;
    border: 1px solid #e4e4e4;
    background: #fff;
    border-radius: 5px;
    left: 0;
  }
  &:after {
    background: url(../img/icon-check.svg) no-repeat center;
    background-size: 100% 100%;
    width: 16px;
    height: 12px;
    left: 8px;
    opacity: 0;
    @include transition(0.2s, ease);
  }
}

label {
  cursor: pointer;
}

.checkbox:checked + label {
  &:after {
    opacity: 1;
  }
}

.select-box {
  padding: 0;
  display: inline-block;
  width: auto;
  min-width: 200px;
  position: relative;
  &:after {
    content: "";
    width: 12px;
    height: 10px;
    background: url(../img/icon-select.png) no-repeat center;
    background-size: 100% 100%;
    position: absolute;
    top: 50%;
    right: 10px;
    @include translateY(-50%);
  }
  select {
    @include reset;
    padding-left: 15px;
    padding-right: 30px;
    width: 100%;
    height: 35px;
    cursor: pointer;
  }
}

.form-btn-wrapper {
  margin-top: 60px;
  margin-bottom: 100px;
  @include flex;
  @include center;
}

.form-btn {
  margin: 15px;
  padding: 15px 60px;
  @include fs(18);
  font-weight: bold;
  color: #fff;
  border-radius: 5px;
  &.disabled {
    opacity: 0.6;
    cursor: no-drop;
  }
  @media only screen and (min-width: 768px) {
    @include transition(0.5s, ease);
    &:hover {
      opacity: 0.6;
    }
  }
}

.form-back {
  background: #b9c4d6;
}

.form-submit {
  background: $blue;
}

select::-ms-expand {
  display: none;
}

.title-catch {
  margin-bottom: 60px;
  font-weight: bold;
  text-align: center;
  &.title-catch-ptn2 {
    @include fs(24);
  }
}

.sche-image {
  img {
    width: 826px;
  }
}

.question-box {
  margin: 60px 0;
  dt {
    padding: 8px 0 8px 60px;
    @include flex;
    @include vertical;
    position: relative;
    span {
      font-size: 1.8rem;
      font-weight: bold;
      line-height: 1.3;
      color: #5C5C5C;
      letter-spacing: 1px;
    }
    button {
      margin-left: 20px;
      padding: 5px 13px;
      line-height: 1;
      padding-right: 25px;
      background: #2AA8C8;
      color: #fff;
      font-size: 1rem;
      min-width: 100px;
      text-align: center;
      display: inline-block;
      position: relative;
      letter-spacing: 1px;
      &:after {
        content: '';
        width: 12px;
        height: 12px;
        background: url(../img/icon-open.svg) no-repeat center;
        background-size: 100% 100%;
        position: absolute;
        top: 50%;
        right: 5px;
        @include translateY(-50%);
      }
      &.active {
        background: #AFAFAF;
        min-width: 110px;
        &:after {
          background: url(../img/icon-qclose.svg) no-repeat center;
          background-size: 100% 100%;
        }
      }
    }
    &:before {
      content: '';
      width: 38px;
      height: 38px;
      background: url(../img/icon-question.svg) no-repeat center;
      background-size: 100% 100%;
      position: absolute;
      top: 50%;
      left: 0;
      @include translateY(-50%);
    }
  }
  dd {
    margin-top: 20px;
    padding: 6px 0 8px 60px;
    position: relative;
    font-size: 1.4rem;
    line-height: 2;
    letter-spacing: 1px;
    color: #707070;
    will-change: height;
    &:before {
      content: '';
      width: 38px;
      height: 38px;
      background: url(../img/icon-answer.svg) no-repeat center;
      background-size: 100% 100%;
      position: absolute;
      top: 0;
      left: 0;
    }
  }
}

.interview-wrapper {
  dt {
    margin-bottom: 30px;
  }
  dd {
    padding-left: 140px;
    p {
      font-size: 1.6rem;
    }
    .interview-box {
      &:last-child {
        margin-bottom: 0;
      }
      p {
        font-size: 1.4rem;
        line-height: 2.5;
      }
    }
    .torigger {
      margin-top: 40px;
      padding: 8px 16px;
      line-height: 1;
      padding-right: 30px;
      background: #2CBE96;
      color: #fff;
      font-size: 1.2rem;
      min-width: 100px;
      text-align: center;
      display: inline-block;
      position: relative;
      letter-spacing: 1px;
      &:after {
        content: "";
        width: 16px;
        height: 16px;
        background: url(../img/icon-open2.svg) no-repeat center;
        background-size: 100% 100%;
        position: absolute;
        top: 50%;
        right: 5px;
        -webkit-transform: translateY(-50%);
        transform: translateY(-50%);
      }
      &.active {
        background: #AFAFAF;
        &:after {
          background: url(../img/icon-qclose.svg) no-repeat center;
          background-size: 100% 100%;
        }
      }
    }
  }
}

.interview-icon {
  margin-right: 20px;
  padding: 10px;
  text-align: center;
  width: 120px;
  display: inline-block;
  background: #009A70;
  color: #fff;
}

.interview-box {
  margin: 60px 0;
  img {
    width: calc(380px / 840px * 100%);
    &.fl {
      margin: 0 25px 5px 0;
      float: left;
    }
    &.fr {
      margin: 0 0 5px 25px;
      float: right;
    }
  }
  p {
    margin-top: -8px;
    font-size: 1.4rem;
  }
}

.float-box {
  overflow: hidden;
  p + p {
    margin-top: 20px;
  }
}

.interview-content {
  margin-bottom: 100px;
}

.caseline {
  margin-bottom: 80px;
  height: 1px;
  background: #249D7C;
  border: none;
}

.case-img {
  width: calc(380px / 840px * 100%);
  img {
    width: 100%;
  }
}

.case-text {
  width: calc(430px / 840px * 100%);
  p + p {
    margin-top: 20px;
  }
}





