
* {
    box-sizing: border-box;
}

html {
    -webkit-text-size-adjust: 100%;
    -ms-text-size-adjust: 100%;
    font-size: 10px;
}

body {
    max-height: 100%;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    line-height: 1.2;
}

p {
    text-align: justify;
    line-height: 2;
    font-weight: 300;
}

a {
    text-decoration: none;
}

img,
iframe {
    vertical-align: bottom;
    max-width: 100%;
}

#wrapper {
    padding-top: 103px;
    font-family: $gosic;
    @include fs(14);
    color: #707070;
    line-height: 1;
    position: relative;
    overflow: hidden;
}

.sp {
    display: none !important;
}

.flex {
    @include flex;
}

.between {
    @include between;
}

.vertical {
    @include vertical;
}

.flex-right {
    @include flexright;
}

.flex-bot {
    @include flexbot;
}

.wrap {
    @include wrap;
}

.center {
    @include center;
}

.btn-reset {
    @include reset;
    cursor: pointer;
    outline: none;
    font-family: $gosic;
}

.tr {
    text-align: right;
}

.tc {
    text-align: center;
    p {
        text-align: center;
    }
}

.min {
    font-family: $mintyo;
}

.re {
    position: relative;
}

input[type="radio"],
input[type="checkbox"] {
    margin-right: 10px;
}

.inner {
    margin-left: auto;
    margin-right: auto;
    padding-left: 20px;
    padding-right: 20px;
    width: 1020px;
    max-width: 100%;
}


.content {
    padding-top: 50px;
    padding-bottom: 50px;
}

.contentHight {
    padding-top: 180px;
    padding-bottom: 180px;
}

.inner1200 {
    width: 1240px;
}

.pb0 {
    padding-bottom: 0;
}

.half {
    width: 50%;
}

.f16 {
    @include fs(16);
}

.sp {
    display: none;
}




